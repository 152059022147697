//@flow

import {declareAction, declareAtom} from "@reatom/core";

const SetUtmData = declareAction('setUtmDataAction');
export const StoreUtmData = declareAction(
  'storeUtmDataAction',
  (payload, store) => {
    const urlParams = new URLSearchParams(window.location.search);
    store.dispatch(SetUtmData({
      source: urlParams.get('utm_source'),
      medium: urlParams.get('utm_medium'),
      campaign: urlParams.get('utm_campaign'),
      gclid: urlParams.get('gclid'),
    }))
  }
);

export const UtmAnalytics = declareAtom(
  'utmAtom',
  {
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    gclid: null,
  },
  on => [
    on(SetUtmData, (state, payload) => {
      return {
        ...state,
        utmSource: payload.source,
        utmMedium: payload.medium,
        utmCampaign: payload.campaign,
        gclid: payload.gclid
      }
    })
  ]);