//@flow

import {declareAtom, declareAction} from "@reatom/core";
import axios from 'axios';
import * as gUtil from '../utils/googlePayment';
import * as common from '../common';
import {PaymentAtom, SetPaymentProcessor, SuccessfulPayment, UnSuccessfulPayment} from "./Payment";
import {AppConfigAtom} from "./AppConfig";
import {gtagEvent, gtagEventNew} from "../utils/gtag";
import {SubscriberAtom, SubscriberLoaded} from "./Subscriber";
import {UtmAnalytics} from "./UtmAnalytics";
import {PaymentType} from "../enums/PaymentTypesEnum";
import {NREndPaymentProcess, NRError, NRStartPaymentProcess, NRSubscribed, NRSuccess} from "./NewRelic";
import { PageSizeExperiment } from '../enums/PageSizeExperimentEnum'
import {SkipStepExperimentEnum} from "../enums/SkipStepExperimentEnum";
import {useSkipStepExperiment} from "../contexts/ExperimentsContext";
// import { remobyEvents, sendPostback } from '../utils/remoby'
// import { useGeneratorExperiment } from '../contexts/ExperimentsContext'

export const InitGooglePayment = declareAction(
  'googleInitPaymentAction',
  (payload, store) => {
    const appConfig = store.getState(AppConfigAtom);

    let paymentType = PaymentType.OneTimePayment;
    let paymentRequestUrl = `${common.api}/payment-request/${appConfig.gateway === 'bluesnap' ? `bluesnap/` : ``}google/single-time`;
    if (typeof payload !== "undefined") {
      paymentType = payload.paymentType;
      switch (paymentType) {
        case PaymentType.OneTimePayment:
          paymentRequestUrl = `${common.api}/payment-request/${appConfig.gateway === 'bluesnap' ? `bluesnap/` : ``}google/single-time`
          break;
        case PaymentType.WeeklySubscription:
          // paymentRequestUrl = `${common.api}/payment-request/google/subscription`;
          // if (payload.bottomSheetExperiment && payload.bottomSheetExperiment === BottomSheetExperiment.New) {
          paymentRequestUrl = `${common.api}/payment-request/google/subscription/variant1`;
          // }
          break;
        case PaymentType.MonthlySubscription:
          paymentRequestUrl = `${common.api}/payment-request/google/subscription-monthly`;
          break;
        case PaymentType.AnnualSubscription:
          paymentRequestUrl = `${common.api}/payment-request/google/subscription-annual`;
          break;
      }
    }
    store.dispatch(SetPaymentType(paymentType));
    const handleSDKInit = () => {
      axios.get(`${common.api}/payment-ready-check/google`)
        .then(response => {
          if (response.status === 200) {
            const processPayment = (paymentData) => {
              return new Promise(function (resolve, reject) {
                setTimeout(function () {
                  store.dispatch(ProcessPayment({
                    paymentData,
                    paymentTypeId: payload.paymentTypeId,
                    experiments: payload.experiments
                  }));
                  resolve({});
                }, 3000);
              });
            }
            const authorizedPaymentHandler = (paymentData) => {
              return new Promise(function (resolve, reject) {
                processPayment(paymentData)
                  .then(function () {
                    resolve({transactionState: 'SUCCESS'});
                  })
                  .catch(function () {
                    resolve({
                      transactionState: 'ERROR',
                      error: {
                        intent: 'PAYMENT_AUTHORIZATION',
                        message: 'Insufficient funds',
                        reason: 'PAYMENT_DATA_INVALID'
                      }
                    });
                  });
              });
            }
            const paymentOptions = response.data.paymentOptions;
            paymentOptions['paymentDataCallbacks'] = {
              onPaymentAuthorized: authorizedPaymentHandler
            }

            const paymentsClient = new google.payments.api.PaymentsClient(paymentOptions);
            paymentsClient
              .isReadyToPay(response.data.isReadyToPayRequest)
              .then(
                response => {
                  if (response.result) {
                    store.dispatch(StorePaymentsClient(paymentsClient));
                    const data = new FormData();
                    data.append('paymentTypeId', payload.paymentTypeId);
                    axios
                      .post(paymentRequestUrl, data)
                      .then(response => {
                        if (response.status === 200) {
                          store.dispatch(StorePaymentRequest(response.data));
                          store.dispatch(GooglePaySupportEnabled());
                        }
                      })
                  }
                }
              )
              .catch(err => {
                // console.log('handle sdk init inner catch')
                console.error(err)
              });
          }
        })
        .catch(err => {
          // console.log('handle sdk init outer catch')
          console.error(err)
        });
    };
    gUtil.loadSDK(handleSDKInit);
  }
);

const StorePaymentRequest = declareAction('googleStorePaymentRequestAction');

const b64EncodeUnicode = str => {
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }
    )
  );
}

const ProcessPayment = declareAction(
  'googleProcessPaymentAction',
  (payload, store) => {

    function b64EncodeUnicode(str) {
      // first we use encodeURIComponent to get percent-encoded UTF-8,
      // then we convert the percent encodings into raw bytes which
      // can be fed into btoa.
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode('0x' + p1);
        }));
    }

    store.dispatch(NRStartPaymentProcess())
    const gpayState = store.getState(GooglePaymentAtom)
    const utmState = store.getState(UtmAnalytics);
    const paymentState = store.getState(PaymentAtom);
    const appState = store.getState(AppConfigAtom);
    const subscriberState = store.getState(SubscriberAtom);
    common.stopPreventLeaving();

    const skipStepExperiment = payload.experiments.skipStepExperiment
    const version = payload.experiments.generatorExperiment

    payload.paymentData.googleTransactionId = gpayState.paymentRequest.i.googleTransactionId;
    const tokenData = appState.gateway === 'bluesnap'
      ? b64EncodeUnicode(JSON.stringify(payload.paymentData))
      : payload.paymentData.paymentMethodData.tokenizationData.token;

    const stepSkipExperimentData = {
      experimentName:'stepSkip',
      experimentValue: skipStepExperiment === SkipStepExperimentEnum.skip
        ? 'flow-3-step'
        : 'flow-4-step',
    }

    const data = {
      token: tokenData,
      paymentTypeId: payload.paymentTypeId,
      processor: 'GOOGLE',
      gateway: paymentState.gateway,
      utmSource: utmState.utmSource,
      utmMedium: utmState.utmMedium,
      utmCampaign: utmState.utmCampaign,
      version: version,
      email: payload.paymentData.email,
      experiments: [
        stepSkipExperimentData
      ],
      gclid: utmState.gclid,
    };
    localStorage.setItem('payeremail', payload.paymentData.email)

    const paymentGateway = appState.gateway === 'bluesnap' ? `bluesnap` : `stripe`


    const googlePayState = store.getState(GooglePaymentAtom);
    let paymentRequestUrl = `${common.api}/subscriber/${appState.fingerprint}/pay/${paymentGateway}/google`;
    switch (googlePayState.paymentType) {
      case PaymentType.OneTimePayment:
        paymentRequestUrl = `${common.api}/subscriber/${appState.fingerprint}/pay/${paymentGateway}/google`;
        break;
      case PaymentType.WeeklySubscription:
        paymentRequestUrl = `${common.api}/subscriber/${appState.fingerprint}/subscribe/${paymentGateway}/google`;
        break;
      case PaymentType.MonthlySubscription:
        paymentRequestUrl = `${common.api}/subscriber/${appState.fingerprint}/subscribe-monthly/${paymentGateway}/google`;
        break;
      case PaymentType.AnnualSubscription:
        paymentRequestUrl = `${common.api}/subscriber/${appState.fingerprint}/subscribe/${paymentGateway}/google`;
        break;
    }

    axios
      .post(paymentRequestUrl, data)
      .then(
        response => {
          store.dispatch(NREndPaymentProcess())
          if (response.data.state === 'active') {
            const newSubscription = {...response.data};
            delete newSubscription.subscriber;
            const newSubscriptionsList = [...subscriberState.subscriber.subscriptions];
            newSubscriptionsList.push(newSubscription);
            store.dispatch(SubscriberLoaded({...subscriberState.subscriber, subscriptions: newSubscriptionsList}))
            store.dispatch(SetPaymentProcessor('GOOGLE'));
            store.dispatch(SuccessfulPayment({ date: response.data.expireDate, experiment: skipStepExperiment }))
            // store.dispatch(FBPTrack(['Lead']));
            let eventData = {};
            let eventShort = '';
            switch (googlePayState.paymentType) {
              case PaymentType.OneTimePayment:
                eventData = {
                  action: 'onetimeSuccess',
                  category: 'onetimepayment',
                  label: 'one time google pay payment'
                }
                eventShort='googlepay_onetime_new';
                break;
              case PaymentType.WeeklySubscription:
              case PaymentType.AnnualSubscription:
                eventData = {
                  action: 'subscriptionSuccess',
                  category: 'subscription',
                  label: 'google pay subscription'
                }
                eventShort = 'googlepay_subscription_new';
                break;
              case PaymentType.MonthlySubscription:
                eventData = {
                  action: 'monthlySubSuccess',
                  category: 'monthlysubscription',
                  label: 'google pay monthly subscription'
                }
                  eventShort = 'googlepay_subscription_monthly_new';
                break;
            }

            if(window.fbq && typeof window.fbq === 'function') {
              window.fbq('track', 'BFGconversion');
            }
            if (window.ttq) {
              window.ttq.track('Subscribe')
              window.ttq.track('BFGconversion')
            }
            gtagEvent(eventData);
            gtagEventNew(eventShort);

            if (SkipStepExperimentEnum.dontSkip === skipStepExperiment) {
              gtagEventNew('googlepay_subscription_original')
            }
            if (SkipStepExperimentEnum.skip === skipStepExperiment) {
              gtagEventNew('googlepay_subscription_experiment')
            }

            // sendPostback({ clickId: appState.remobyId, eventName: remobyEvents[4] })
            store.dispatch(NRSubscribed(NRSuccess))
          } else {
            store.dispatch(UnSuccessfulPayment());
            store.dispatch(NRSubscribed(NRError))
          }
        }
      )
      .catch(console.error);
  }
)

export const ShowGooglePaymentRequest = declareAction(
  'googleShowPaymentRequestAction',
  (payload, store) => {
    const googlePayState = store.getState(GooglePaymentAtom);
    const paymentDataRequest = googlePayState.paymentRequest;

    googlePayState
      .paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then(paymentData => {
      })
      .catch(err => {
        const container = document.getElementById('google-pay-button');
        if (container) {
          container.style.display = 'initial';
        }
        // console.log('show g. payment request catch')
        console.error(err)
      });
  }
);

const GooglePaySupportEnabled = declareAction('googlePaySupportEnabledAction');
const StorePaymentsClient = declareAction('googleStorePaymentClientAction');
export const ShowButton = declareAction('googleShowButtonAction');
const SetPaymentType = declareAction('googleSetPaymentTypeAction');

export const GooglePaymentAtom = declareAtom(
  'googlePaymentAtom',
  {
    googlePayEnabled: false,
    paymentsClient: null,
    paymentRequest: null,
    buttonShown: false,
    paymentType: PaymentType.OneTimePayment,
  },
  on => [
    on(SetPaymentType, (state, payload) => {
      return {...state, paymentType: payload};
    }),
    on(GooglePaySupportEnabled, (state, payload) => {
      return {...state, googlePayEnabled: true};
    }),
    on(StorePaymentsClient, (state, payload) => {
      return {...state, paymentsClient: payload};
    }),
    on(StorePaymentRequest, (state, payload) => {
      return {...state, paymentRequest: payload};
    }),
    on(ShowButton, (state, payload) => {
      return {...state, buttonShown: true};
    })
  ]
);